module.exports = {
  linkResolver(doc) {
    switch (doc.type) {
      case "page":
        return `/${doc.uid}`
      case "about":
        return `/about`
      case "case":
        return `/case/${doc.uid}`
      default:
        return `/`
    }
  },
}
