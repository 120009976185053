import React from "react"
import GlobalContextProvider from "./src/context/GlobalContextProvider"
const { registerLinkResolver } = require('@prismicio/gatsby-source-prismic-graphql');
const { linkResolver } = require('./src/utils/linkResolver');

registerLinkResolver(linkResolver);

export const wrapRootElement = ({ element }) => {
  return <GlobalContextProvider>{element}</GlobalContextProvider>
}

if (typeof window === 'undefined') {
  global.window = {}
}