import React from "react"

export const GlobalStateContext = React.createContext()
export const GlobalDispatchContext = React.createContext()

const initialState = {
  pointer: false,
  windowSize:{
    width: null,
    height:null
  },
  cursor:{
    visible:false,
    text: null
  },
  work:null,
  statements:null,
  introTransitionDone:false,
  navActive: false,
  cookieNoticeFired: false,
  loaded: false,
}

function reducer(state, action) {
  switch (action.type) {
    case "SET_POINTER": {
      return{
        ...state,
        pointer: action.val
      }
    }
    case "SET_WINDOWSIZE": {
      return{
        ...state,
        windowSize: action.windowSize
      }
    }
    case "SET_CURSOR": {
      return{
        ...state,
        cursor:{
          visible:action.cursor.visible,
          text: action.cursor.text
        }
      }
    }
    case "SET_WORK": {
      return{
        ...state,
        work: action.work
      }
    }
    case "SET_STATEMENTS": {
      return{
        ...state,
        statements: action.statements
      }
    }
    case "SET_ABOUT": {
      return{
        ...state,
        about: action.about
      }
    }
    case "SET_INTRO_TRANSITION": {
      return{
        ...state,
        introTransitionDone: action.introTransitionDone
      }
    }
    case "SET_NAV_ACTIVE": {
      return{
        ...state,
        navActive: action.navActive
      }
    }
    case "SET_COOKIE_NOTICE_FIRED": {
      return{
        ...state,
        cookieNoticeFired: action.cookieNoticeFired
      }
    }

    case "SET_LOADED": {
      return{
        ...state,
        loaded: action.loaded
      }
    }

    default:
      throw new Error("Bad Action Type")
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalContextProvider