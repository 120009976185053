module.exports = [{
      plugin: require('../node_modules/@prismicio/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"wearehero","defaultLang":"en-us","path":"/preview","previews":true,"omitPrismicScript":true,"accessToken":"MC5YMktjX0JNQUFDSUExWmZi.77-9WlMeVjELW--_vSFcKyLvv70GLzkO77-9RO-_vXcv77-977-9Qe-_ve-_vWYoS04","pages":[{"type":"Page","match":"/:uid","previewPath":"/page-preview","component":"/opt/build/repo/src/templates/page.js"},{"type":"Case","match":"/case/:uid","previewPath":"/case-preview","component":"/opt/build/repo/src/templates/case.js"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#000","theme_color":"#DF5036","display":"minimal-ui","icon":"src/svg/favicon.svg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"9505b04f68786c72ed8a190af5b385e6"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://wearehero.dk"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics-gdpr/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-253914431-1","enableDevelopment":false,"anonymizeIP":true,"autoStartWithCookiesEnabled":false,"reactGaOptions":{"debug":false,"gaOptions":{"sampleRate":10}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
